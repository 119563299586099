<template lang="html">
<div class="content">
  <el-card class="centered-card">
  <h1>Login</h1>
  <p>Please log-in to upload your project</p>
  <div id="firebaseui-auth-container"></div>
  </el-card>
  </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import { config } from '../helpers/firebaseConfig';

export default {
  name: 'auth',
  mounted() {
    var uiConfig = {
      signInSuccessUrl: '/profile',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // # firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInFlow: 'popup',
    };
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(function() {
      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
    })
  },
}
</script>
<style scoped>
#firebase-auth-container {
  height: 400px;
}

.centered-card {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
